<template>
  <div>
    <b-form inline>
      <b-form-input class="mr-2" name="person" placeholder="person" v-model="currentPersonName" />
      <b-form-select class="mr-2" name="rank" placeholder="grad" v-model="selectedRank" :options="rankOptions"/>
      <b-button v-b-modal.select-person-modal class="m-3">
	<span v-if="person">Byt person</span>
	<span v-else>Välj person</span>
      </b-button>
      <b-button variant="primary" @click="setMemberRank()">Spara</b-button>
    </b-form>
    <SelectPersonModal id="select-person-modal" @person="setPerson" :allowChangingPeriod="true" />
  </div>
</template>

<script>
import { db } from '@/firebaseConfig.js'
import SelectPersonModal from '@/components/SelectPersonModal.vue'
import { rankNames } from '@/utils/ranks.js'
import { doc, updateDoc } from 'firebase/firestore'

export default {
  components: { SelectPersonModal },
  data() {
    return {
      rankOptions: rankNames.map((name, index) => { return { value: index, text: name}}),
      selectedRank: undefined,
      person: undefined
    }
  },
  computed: {
    currentPersonName() {
      return this.person ? `${this.person.firstName} ${this.person.lastName}: ${this.person.id}` : ''
    }
  },
  methods: {
    setPerson(person) {
      this.person = person
    },
    setMemberRank() {
      updateDoc(doc(db, 'members', this.person.id), { rank: this.selectedRank })
        .then(() => console.log('Member updated'))
        .catch((error) => console.log(`Failed to update: ${error}`))
    }
  }
}
</script>
