<template>
    <div>
        <h1>Grader</h1>
        <AddRankToMember />
        <h1>Test</h1>
        <b>Gamla grader</b>
        <ul>
            <li v-for="rank in ranks" :key="rank"><RankImage :rank="rank" /> - {{ rank }}</li>
        </ul>
        <b>Nya grader</b>
        <ul>
            <li v-for="rank in allranks" :key="rank"><RankImage :rank="rank" /> - {{ rank }}</li>
        </ul>
        <p>Done.</p>
    </div>
</template>

<script>
 import AddRankToMember from '@/components/AddRankToMember.vue'
 import RankImage from '@/components/RankImage.vue'
 import { newRanks } from '@/utils/ranks.js'

 export default {
     components: { AddRankToMember, RankImage },
     data() {
         return {
             ranks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
             allranks: [ ...newRanks]
         }
     }
 }
</script>
